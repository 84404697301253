import {
  getDayWorkByIdAsync,
  updateDayWorkByIdAsync,
  addVoToDayWorksAsync,
  addVoCategoryToDayWorksAsync,
  getUninvoicedDayWorkAsync,
  exportDayworkToCsvAsync,
  unassignDayworkVOAsync, getDayWorkLogsByIdAsync,
} from "../api/day_work";

export const getDayWorkById = async (id, filter, page, pageSize) =>
  await getDayWorkByIdAsync(id, filter, page, pageSize);

export const getDayWorkLogs = async (dayworkId) => await getDayWorkLogsByIdAsync(dayworkId);

export const updateDayWorkById = async (id, status, comment) =>
  await updateDayWorkByIdAsync(id, status, comment);

export const toggleDayworkHold = async (id) =>
    await toggleDayworkHold(id);

export const addVoToDayWorks = async (data) => await addVoToDayWorksAsync(data);

export const addVoCategoryToDayWorks = async (id, data) => await addVoCategoryToDayWorksAsync(id, data);

export const getUninvoicedDayWork = async (id, page) => await getUninvoicedDayWorkAsync(id, page);

export const exportDayworkToCsv = async(data) => await exportDayworkToCsvAsync(data);

export const unassignDayworkVO = async (id) => await unassignDayworkVOAsync(id);
